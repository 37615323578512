export const IS_TESTING = ["test", "testlocal"].includes(
  String(process.env.REACT_APP_NODE_ENV)
);
export const DISABLED_RECAPTCHA =
  process.env.REACT_APP_DISABLED_RECAPTCHA === "true";
export const IS_DEV = String(process.env.REACT_APP_NODE_ENV) === "development";
export const WATCH_SUB_SLUG_OBJ: Record<string, string> = {
  MY_CHANNEL: "my-channel",
  TREASURE_GAME: "treasure-games",
  TREASURE_GAME1: "treasure-games1",
  TRENDING: "trending",
  FOLLOWING: "following",
  REGIONS: "regions",
  WINNERS: "winners",
  WINNERS_OLD: "winners",
};
export const WATCH_SUB_SLUG: string[] = [
  WATCH_SUB_SLUG_OBJ.MY_CHANNEL,
  WATCH_SUB_SLUG_OBJ.TREASURE_GAME,
  WATCH_SUB_SLUG_OBJ.TRENDING,
  WATCH_SUB_SLUG_OBJ.FOLLOWING,
  WATCH_SUB_SLUG_OBJ.REGIONS,
  WATCH_SUB_SLUG_OBJ.WINNERS,
];
export const WATCH_SUB_TITLE: Record<string, string> = {
  [WATCH_SUB_SLUG_OBJ.MY_CHANNEL]: "My Channel",
  [WATCH_SUB_SLUG_OBJ.TREASURE_GAME]: "Treasure Game$",
  [WATCH_SUB_SLUG_OBJ.TRENDING]: "Trending",
  [WATCH_SUB_SLUG_OBJ.FOLLOWING]: "Following",
  [WATCH_SUB_SLUG_OBJ.REGIONS]: "Regions",
  [WATCH_SUB_SLUG_OBJ.WINNERS]: "Winners",
};
export const WATCH_SUB_SLUG_WITHOUT_MC = WATCH_SUB_SLUG.filter(
  (v) => v !== WATCH_SUB_SLUG_OBJ.MY_CHANNEL
);
export const POST_STATUS_VALUES = {
  UPLOADING: "uploading",
  UPLOADING_FAILED: "uploading_failed",
  THUMBNAIL: "thumbnail",
  THUMBNAIL_FAILED: "thumbnail_failed",
  VERIFYING: "verifying",
  VERIFYING_FAILED: "verifying_failed",
  VERIFIED: "verified",
  DRAFT: "draft",
  PROCESSING: "processing",
  PUBLISHED: "published",
  BANNED: "banned",
  ERROR: "error",
};
export const POST_STATUS_MAPPING: Record<string, string> = {
  [POST_STATUS_VALUES.UPLOADING]: "Uploading",
  [POST_STATUS_VALUES.UPLOADING_FAILED]: "uploading Failed",
  [POST_STATUS_VALUES.THUMBNAIL]: "Thumbnailing...",
  [POST_STATUS_VALUES.THUMBNAIL_FAILED]: "Thumbnail Failed",
  [POST_STATUS_VALUES.VERIFYING]: "Verifying media...",
  [POST_STATUS_VALUES.VERIFYING_FAILED]: "Verifying Failed",
  [POST_STATUS_VALUES.VERIFIED]: "Verified",
  [POST_STATUS_VALUES.DRAFT]: "Draft",
  [POST_STATUS_VALUES.PROCESSING]: "Processing...",
  [POST_STATUS_VALUES.PUBLISHED]: "Published",
  [POST_STATUS_VALUES.ERROR]: "Error",
};
export const NEW_ID = "new_id";
export const ANNOUNCEMENT_IDS: Record<string, string> = {
  CURRENT: "current-announcements",
  PAST: "past-announcements",
};
export const ACCEPT_UPLOAD_IMAGES =
  "image/png, image/gif, image/jpeg, image/heif, image/heic";
export const ACCEPT_UPLOAD_VIDEOS =
  "video/quicktime, video/mp4, video/mpeg, video/avi, video/x-ms-wmv, video/x-flv, video/3gpp, video/webm";
export const GAME_HEADER_TIME_UP_CLASSNAME = "game-header-time-up";
export const PRIZE_SUB_SLUG_OBJ: Record<string, string> = {
  CURRENT_CONTESTS: "current-contests",
  UPCOMING_CONTESTS: "upcoming-contests",
  PAST_CONTESTS: "past-contests",
  CONTEST_WINNERS: "contest-winners",
};
export const PRIZE_SUB_SLUG: string[] = [
  PRIZE_SUB_SLUG_OBJ.CURRENT_CONTESTS,
  PRIZE_SUB_SLUG_OBJ.UPCOMING_CONTESTS,
  PRIZE_SUB_SLUG_OBJ.PAST_CONTESTS,
  PRIZE_SUB_SLUG_OBJ.CONTEST_WINNERS,
];
export const PRIZE_SUB_TITLE: Record<string, string> = {
  [PRIZE_SUB_SLUG_OBJ.CURRENT_CONTESTS]: "Current Contests",
  [PRIZE_SUB_SLUG_OBJ.UPCOMING_CONTESTS]: "Upcoming Contests",
  [PRIZE_SUB_SLUG_OBJ.PAST_CONTESTS]: "Past Contests",
  [PRIZE_SUB_SLUG_OBJ.CONTEST_WINNERS]: "Contest Winners",
};
export const DEFAULT_DATE_FORMAT = "MM/DD/YYYY";
export const BE_DATE_FORMAT = "YYYY-MM-DD";
export const MASK_PATTERN = {
  DATE: {
    MASK: "99/99/9999",
    PLACEHOLDER: DEFAULT_DATE_FORMAT,
  },
};
export const APP_LINKS = {
  APPLE: "https://apps.apple.com/us/app/treasure-game%24/id6452119815",
  GOOGLE: "https://play.google.com/store/apps/details?id=com.treasuregames",
};
