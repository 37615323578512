import find from "lodash/find";
import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { RegionListProps } from "src/pages/Play/SelectRegions/Components/RegionList/types";
import { useReducerData } from "src/store/hooks";
import {
  AdvantageClue,
  PurchaseHistoryProps,
  RangeFinder,
  RangePlanProps,
} from "src/types/clue";
import { TGS_BASE_URL } from "src/utils/constants";
import { useClueActions, useRegionActions } from "src/utils/useActions";
import { useCluesData, useRegionData } from "src/utils/useData";
import hotAndColdBarometer from "./assets/hot-and-cold-barometer.png";
import rangeFinder3 from "./assets/range-finder-3.png";
import rangeFinder from "./assets/range-finder.png";
import treasureCompass from "./assets/treasure-compass.png";
import treasureViews from "./assets/treasure-views.png";
import AdvantageCluesCard from "./components/AdvantageCluesCard";
import HotColdBarometerModal from "./components/HotColdBarometerModal";
import PurchaseHistory from "./components/PurchaseHistory";
import RangeFinderModal from "./components/RangeFinderModal";
import TreasureCompassModal from "./components/TreasureCompassModal";
import TreasureViewsModal from "./components/TreasureViewsModal";
import classes from "./styles.module.scss";

const getAdvantageClueList = (
  rangeFinderOptions: RangePlanProps[] = [],
  treasureViewsOptions: RangePlanProps[] = [],
  barometerOptions: RangePlanProps[] = [],
  barometerTwoOptions: RangePlanProps[] = [],
  compassOptions: RangePlanProps[] = [],
  compassTwoOptions: RangePlanProps[] = [],
  existRangeFinder = false,
  existRangeFinder3 = false,
  existTreasureViews = false,
  existBarometer = false,
  existBarometerTwo = false,
  existCompass = false,
  existCompassTwo = false,
  treasureViewsData: RangeFinder[]
) => {
  const ADVANTAGE_CLUES: AdvantageClue[] = [];

  const TreasureViewsPeriodInDays =
    treasureViewsData[0]?.purchase_period_in_days ?? 0;
  const TreasureViewsAvailableOnDays =
    treasureViewsData[0]?.available_on_day ?? 0;

  if (existRangeFinder) {
    ADVANTAGE_CLUES.push({
      id: 1,
      title: "Range Finder",
      description: (
        <>
          Set a pin on a map to have the Range Finder check whether that pin is
          within the purchased distance from the Outer Zone.
          <br />
          <br />
          Distances can be purchased at certain time frames.{" "}
          <a
            href={`${TGS_BASE_URL}/a/wl-advantage-clues#range-finder`}
            target="_blank"
            rel="noreferrer"
          >
            Learn more...
          </a>
        </>
      ),
      plans: rangeFinderOptions,
      isForMobile: false,
      type: "range_finder",
      img: rangeFinder,
    });
  }
  if (existRangeFinder3) {
    ADVANTAGE_CLUES.push({
      id: 1,
      title: "Range Finder 3.0",
      description: (
        <>
          There are two types of Range Finder 3.0 Clues available; Red & Green
          Zones. These are{" "}
          <span>
            <strong>predetermined</strong>{" "}
          </span>{" "}
          areas of different sizes that overlap{" "}
          <span style={{ color: "#61B036" }}>(Green)</span> or don't{" "}
          <span style={{ color: "#F93939" }}>(Red)</span> with the Lamp's Outer
          Zone.
          <br />
          <br />
          There is a{" "}
          <span>
            <strong>fixed</strong>
          </span>{" "}
          number of Red and Green zones you can purchase. They are made{" "}
          <span>
            <strong>available at different timeframes and prices.</strong>
          </span>
        </>
      ),
      plans: [],
      isForMobile: true,
      type: "range_finder",
      img: rangeFinder3,
    });
  }

  if (existTreasureViews) {
    ADVANTAGE_CLUES.push({
      id: 2,
      title: "Treasure Views",
      description: (
        <>
          You can purchase up to <b>five 2 Packs</b> of photo reveals that are
          taken from the perspective of the hidden lamp. One 2 Pack is available
          to purchase every {TreasureViewsPeriodInDays} days starting on Day{" "}
          {TreasureViewsAvailableOnDays}.{" "}
          <a
            href={`${TGS_BASE_URL}/a/wl-advantage-clues#treasure-views`}
            target="_blank"
            rel="noreferrer"
          >
            Learn more...
          </a>
        </>
      ),
      plans: treasureViewsOptions,
      isForMobile: false,
      type: "treasure_view_images",
      img: treasureViews,
    });
  }

  if (existBarometer || existBarometerTwo) {
    ADVANTAGE_CLUES.push({
      id: 3,
      title: "Hot & Cold Barometer",
      description: (
        <>
          The Hot & Cold Barometer Advantage Clue allows a player to activate a
          hot and cold interface that shows them if they are generally moving
          towards or away from the Lamp's Outer Zone.
          <br />
          <br />
          The Barometer lasts for 10 continuous minutes.
        </>
      ),
      plans: existBarometer ? barometerOptions : barometerTwoOptions,
      isForMobile: true,
      type: "hot_and_cold_barometer",
      img: hotAndColdBarometer,
    });
  }

  if (existCompass) {
    ADVANTAGE_CLUES.push({
      id: 4,
      title: "Treasure Compass",
      description: (
        <>
          The Treasure Compass Advantage Clue only unlocks for players who have
          successfully placed the <b>10 mile</b> pin for the{" "}
          <b>Range Finder Advantage Clue.</b> The Treasure Compass points a
          player in the direction of the Inner Zone. The Compass lasts for 10
          continuous minutes.
        </>
      ),
      plans: compassOptions,
      isForMobile: true,
      type: "treasure_compass",
      img: treasureCompass,
    });
  }

  if (existCompassTwo) {
    ADVANTAGE_CLUES.push({
      id: 4,
      title: "Treasure Compass",
      description: (
        <>
          <b>Range Finder Advantage Clue.</b> The Treasure Compass points a
          player in the direction of the Lamp's Inner Zone.
          <br />
          <br />
          The Compass lasts for 10 continuous minutes.
        </>
      ),
      plans: compassTwoOptions,
      isForMobile: true,
      type: "treasure_compass",
      img: treasureCompass,
    });
  }

  return ADVANTAGE_CLUES;
};

const CluesPurchase = () => {
  const { getRegion } = useRegionActions();
  const {
    region: {
      data: { id },
    },
  } = useRegionData();
  const [rangeFinderModal, setRangeFinderModal] = useState<boolean>(false);
  const [treasureViewsModal, setTreasureViewsModal] = useState<boolean>(false);
  const [hotcoldModal, setHotcoldModal] = useState<boolean>(false);
  const [treasureModal, setTreasureModal] = useState<boolean>(false);
  const [searchParams] = useSearchParams();
  const {
    getRangeFinderBuyOptions,
    getAdvantageCluesPurchaseHistoryData,
    getTreasureViewsBuyOptions,
    resetAdvantageCluesData,
  } = useClueActions();
  const { region } = useRegionData();
  const { seasons = [] } = region.data;
  const currentSeason = searchParams.get("season");
  const season = seasons.find(
    (season) => season?.id?.toString() === currentSeason
  );
  const lampId = season?.lamp?.id as number;

  const purchasedTreasureViewsMedia = season?.lamp?.media || {
    purchased: 0,
    total: 10,
    purchased_media: [],
  };
  const { data: regionList }: { data: RegionListProps[] } = useReducerData(
    "region",
    "regionList",
    {
      data: [],
      loading: false,
    }
  );
  const thisRegion: any = find(regionList, {
    id: Number(searchParams.get("region")),
  });
  const regionLampData = thisRegion?.lamp_data || [];
  const thisLampData = find(regionLampData, { id: lampId });

  const [step, setStep] = useState(1);
  const [initialRangeFinderResultData, setInitialRangeFinderResultData] =
    useState<PurchaseHistoryProps>();

  const [initialTreasureViewsResultData, setInitialTreasureViewsResultData] =
    useState<PurchaseHistoryProps>();

  const refreshCluePurchaseData = () => {
    getRangeFinderBuyOptions(lampId);
    getTreasureViewsBuyOptions(lampId);
    getAdvantageCluesPurchaseHistoryData(lampId);
  };

  useEffect(() => {
    if (lampId) {
      refreshCluePurchaseData();
    } else {
      resetAdvantageCluesData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lampId]);

  const toggleModal = () => {
    setRangeFinderModal(false);
    setTreasureViewsModal(false);
    setHotcoldModal(false);
    setTreasureModal(false);
    setStep(1);
  };

  const handleOpenModal = (type: string) => {
    if (!lampId) return;
    if (type === "range_finder") {
      setRangeFinderModal(true);
    } else if (type === "treasure_view_images") {
      setTreasureViewsModal(true);
    } else if (type === "hot_and_cold_barometer" || type === "barometer_2") {
      setHotcoldModal(true);
    } else if (type === "treasure_compass" || type === "compass_2") {
      setTreasureModal(true);
    } else {
      return;
    }
  };

  const handleOpenPurchaseHistoryModal = (history: PurchaseHistoryProps) => {
    if (history.clue.type === "range_finder") {
      setRangeFinderModal(true);
      setStep(4);
      setInitialRangeFinderResultData(history);
    } else {
      setTreasureViewsModal(true);
      setStep(3);
      setInitialTreasureViewsResultData(history);
    }
  };

  const updateRegion = async () => {
    getRegion(id.toString());
  };

  const { rangeFinderBuyOptions, treasureViewsBuyOptions } = useCluesData();

  const rangeFinderData = rangeFinderBuyOptions?.data || [];

  const getAdvClueData = (type: string) =>
    rangeFinderData?.filter((op) => op?.type === type).map((op) => op) || [];

  const formattedRangeFinderData =
    rangeFinderData
      ?.filter((op) => op?.type === "range_finder")
      ?.map((option: RangeFinder) => {
        return { price: option?.amount, value: option?.range?.toString() };
      }) || [];
  const formattedRangeFinder3Data =
    rangeFinderData
      ?.filter(
        (op) =>
          op?.type === "range_finder_3_green_zone" ||
          op?.type === "range_finder_3_red_zone"
      )
      ?.map((option: RangeFinder) => {
        return { price: option?.amount, value: option?.range?.toString() };
      }) || [];
  const formattedBarometerData =
    rangeFinderData
      ?.filter((op) => op?.type === "barometer")
      ?.map((option) => {
        return { price: option?.amount, value: "For 10 Minutes" };
      }) || [];
  const formattedBarometerTwoData =
    rangeFinderData
      ?.filter((op) => op?.type === "barometer_2")
      ?.map((option) => {
        return { price: option?.amount, value: "For 10 Minutes" };
      }) || [];
  const formattedCompassData =
    rangeFinderData
      ?.filter((op) => op?.type === "compass")
      ?.map((option) => {
        return { price: option?.amount, value: "For 10 Minutes" };
      }) || [];
  const formattedCompassTwoData =
    rangeFinderData
      ?.filter((op) => op?.type === "compass_2")
      ?.map((option) => {
        return { price: option?.amount, value: "For 10 Minutes" };
      }) || [];
  const formattedTreasureViewsData =
    treasureViewsBuyOptions?.data
      ?.filter(
        (op) => op?.type === "treasure_view_images" && op?.image_count === 2
      )
      ?.map((option) => {
        return {
          price: option?.amount || "",
          value:
            option?.image_count === 1
              ? "Single "
              : `${option?.image_count} Pack`,
        };
      }) || [];

  const existTreasureCompass: boolean = formattedCompassData?.length > 0;
  const existTreasureCompassTwo: boolean = formattedCompassTwoData?.length > 0;
  const existTreasureBarometer: boolean = formattedBarometerData?.length > 0;
  const existTreasureBarometerTwo: boolean =
    formattedBarometerTwoData?.length > 0;
  const existRangeFinder: boolean = formattedRangeFinderData?.length > 0;
  const existTreasureViews: boolean = formattedTreasureViewsData?.length > 0;

  const existRangeFinder3: boolean = formattedRangeFinder3Data?.length > 0;

  const advClues = getAdvantageClueList(
    formattedRangeFinderData,
    formattedTreasureViewsData,
    formattedBarometerData,
    formattedBarometerTwoData,
    formattedCompassData,
    formattedCompassTwoData,
    existRangeFinder,
    existRangeFinder3,
    existTreasureViews,
    existTreasureBarometer,
    existTreasureBarometerTwo,
    existTreasureCompass,
    existTreasureCompassTwo,
    getAdvClueData("treasure_view_images")
  );

  return (
    <>
      {rangeFinderModal ? (
        <RangeFinderModal
          isOpen={rangeFinderModal}
          onClose={toggleModal}
          lampId={lampId}
          initialStep={step}
          initialResultData={initialRangeFinderResultData}
          refreshCluePurchaseData={refreshCluePurchaseData}
          lampData={thisLampData}
        />
      ) : null}
      {treasureViewsModal ? (
        <TreasureViewsModal
          isOpen={treasureViewsModal}
          onClose={toggleModal}
          lampId={lampId}
          purchasedTreasureViewsMedia={purchasedTreasureViewsMedia}
          initialStep={step}
          initialResultData={initialTreasureViewsResultData}
          refreshCluePurchaseData={refreshCluePurchaseData}
          updateRegion={updateRegion}
        />
      ) : null}
      {hotcoldModal ? (
        <HotColdBarometerModal isOpen={hotcoldModal} onClose={toggleModal} />
      ) : null}
      {treasureModal ? (
        <TreasureCompassModal isOpen={treasureModal} onClose={toggleModal} />
      ) : null}

      <div className={classes.mainContainer}>
        {thisLampData.status == "paused" && (
          <div className={classes.warningText}>
            <span>Advantage Clues are disabled while the game is on pause</span>
          </div>
        )}
        <div className={classes.hintText}>
          <span>
            The advantage clues may help you in your search for the Wish Lamp.
            You are responsible to read{" "}
            <a
              href={`${TGS_BASE_URL}/a/wl-advantage-clues/`}
              target="_blank"
              rel="noreferrer"
            >
              all the information related to their use
            </a>
            .
          </span>
        </div>
        <div className={classes.container}>
          {advClues.map((item: AdvantageClue, index) => {
            return (
              <AdvantageCluesCard
                item={item}
                key={index}
                action={handleOpenModal}
              />
            );
          })}
        </div>
        <PurchaseHistory handleOpenModal={handleOpenPurchaseHistoryModal} />
      </div>
    </>
  );
};

export default CluesPurchase;
