import cx from "classnames";
import React, { useEffect, useState } from "react";
import { useLocation, useSearchParams } from "react-router-dom";
import { toast } from "react-toastify";
import {
  Nav,
  NavItem,
  NavLink,
  Spinner,
  TabContent,
  TabPane,
} from "reactstrap";
import { ReactComponent as GridIcon } from "src/assets/icons/grid-icon.svg";
import { ReactComponent as ListIcon } from "src/assets/icons/list-icon.svg";
import UpgradeModal from "src/components/UpgradeModal";
import { useIsUserProfileFreeTrial } from "src/helpers/hooks/useUserProfile";
import { ClueProps } from "src/pages/Play/Clue/types";
import { useReducerData } from "src/store/hooks";
import { useClueActions } from "src/utils/useActions";
import { useCluesData, useRegionData } from "src/utils/useData";
import { isClueCompleted } from "src/utils/utils";
import CluesPurchase from "./CluePurchase";
import Clues from "./Clues";
import UnlockedClueModal from "./Clues/UnlockedCLueModal";
import LampPausedModal from "./LampPausedModal";
import classes from "./styles.module.scss";

type Props = {
  loading: boolean;
};

export const useQrLocation = () => {
  const location = useLocation();
  const state = location.state || {};

  if (state?.tab) {
    return { qrSelectedTab: state.tab };
  }
  return {
    qrSelectedTab: "clues",
  };
};

const ClueDashboard: React.FC<Props> = ({ loading = false }) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const { getAdvantageCluesCount } = useClueActions();
  const season = searchParams.get("season") || "1";
  const [activeTab, setActiveTab] = useState("clues");
  const { qrSelectedTab } = useQrLocation();

  const { data: clues = [] }: { data: ClueProps[] } = useReducerData(
    "clue",
    "clues",
    {
      data: [],
      loading: false,
    }
  );

  const { getUnlockedCluesData } = useCluesData();

  const {
    region: { data },
  } = useRegionData();
  const { seasons = [] } = data;

  const [tabsCount, setTabsCount] = useState<{ [key: string]: number }>({
    clue: 0,
    "not-completed-clues": 0,
    "completed-clues": 0,
    "clue-purchase": 0,
  });
  const [showPausedModal, setShowPausedModal] = useState(false);
  const [advCluesCount, setAdvCluesCount] = useState<number | null>(null);
  const [unlockedCluesCount, setUnlockedCluesCount] = useState(0);

  const isFreeTrial = useIsUserProfileFreeTrial();
  const showUpgradeModal = searchParams.get("show_upgrade_modal") === "true";
  const isAllFreeCluesCompleted =
    searchParams.get("all_clues_completed") === "true";

  const completedClues = clues?.filter((clue) => {
    return isClueCompleted(clue);
  })?.length;

  const inCompleteClues = clues?.filter((clue) => {
    return !isClueCompleted(clue);
  })?.length;

  const lamp = seasons?.find((s) => String(s.id) === String(season))?.lamp;
  const lampId = lamp?.id;
  const isAllLampFound = lamp?.is_found || false;
  const isLampPaused = lamp?.is_paused || false;
  const pausedReason = lamp?.pause_reason || "";

  useEffect(() => {
    if (!loading && isLampPaused) {
      setShowPausedModal(true);
    }
    return () => {
      setShowPausedModal(false);
    };
  }, [loading, isLampPaused]);

  useEffect(() => {
    if (qrSelectedTab !== "clues") {
      setActiveTab(qrSelectedTab);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!lampId || lampId == 0) return;

    (async () => {
      const advclues = await getAdvantageCluesCount(lampId);
      setAdvCluesCount(advclues.length);
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lampId]);

  useEffect(() => {
    setUnlockedCluesCount(getUnlockedCluesData?.data?.length || 0);
  }, [getUnlockedCluesData]);

  const tabs = [
    {
      id: "clues",
      label: "Clues",
      disabled: false,
      clueCount: clues?.length,
    },
    {
      id: "not-completed-clues",
      label: "Not Completed",
      disabled: isAllLampFound,
      clueCount: inCompleteClues,
    },
    {
      id: "completed-clues",
      label: "Completed",
      disabled: isAllLampFound,
      clueCount: completedClues,
    },
    {
      id: "clue-purchase",
      label: "Advantage Clues",
      disabled: isAllLampFound,
    },
  ];

  if (!isFreeTrial && unlockedCluesCount > 0) {
    tabs.push({
      id: "unlocked-clues",
      label: "Unlocked Clues",
      disabled: isAllLampFound,
      clueCount: unlockedCluesCount,
    });
  }
  const [clueView, setClueView] = useState<"list" | "grid">("list");

  useEffect(() => {
    if (clues) {
      setTabsCount((prev) => ({ ...prev, clue: clues.length }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clues]);

  const toggle = (tab: React.SetStateAction<string>) => {
    if (activeTab !== tab) {
      if (
        tab === "clue-purchase" &&
        advCluesCount !== null &&
        advCluesCount === 0
      ) {
        toast.error(
          "Advantage Clues are currently disabled for this Lamp. Follow the Announcements section to get updates."
        );
      } else {
        setActiveTab(tab);
      }
    }
  };

  const handleCloseModal = () => {
    searchParams.delete("show_upgrade_modal");
    if (isAllFreeCluesCompleted) {
      searchParams.delete("all_clues_completed");
    }
    setSearchParams(searchParams);
  };

  return (
    <div className={classes.container}>
      {showUpgradeModal && isFreeTrial && (
        <UpgradeModal
          isOpen={showUpgradeModal}
          onClose={handleCloseModal}
          submitText="Upgrade to Full Access"
          isAllFreeCluesCompleted={isAllFreeCluesCompleted}
        />
      )}
      {showPausedModal && (
        <LampPausedModal
          isOpen={showPausedModal}
          message={pausedReason}
          onClose={() => {
            setShowPausedModal(false);
          }}
        />
      )}
      <Nav tabs className={classes.nav}>
        <div className={classes.mainTabs}>
          {tabs.map(({ id, label, clueCount }) => {
            return (
              <NavItem
                key={id}
                className={cx(classes.navItem, {
                  // [classes.disabled]: disabled,
                })}
              >
                <NavLink
                  className={cx({
                    [classes.active]: activeTab === id,
                  })}
                  onClick={() => {
                    toggle(id);
                  }}
                >
                  {label} {tabsCount[id] > 0 && `(${tabsCount[id]})`}
                  {id !== "clue-purchase" && ` (${clueCount})`}
                </NavLink>
              </NavItem>
            );
          })}
        </div>
        {activeTab !== "clue-purchase" && (
          <div className={classes.viewSelect}>
            <GridIcon
              {...(clueView === "grid" ? { fill: "#6C69F8" } : {})}
              onClick={() => setClueView("grid")}
            />
            <ListIcon
              {...(clueView === "list" ? { fill: "#6C69F8" } : {})}
              onClick={() => setClueView("list")}
            />
          </div>
        )}
      </Nav>
      <TabContent
        className={cx(classes.tabContent, {
          // [classes.allFound]: currentRegion?.lamp_count === 0,
        })}
        activeTab={activeTab}
      >
        <TabPane tabId="clues">
          {activeTab === "clues" && (
            <Clues
              tab={activeTab}
              loading={loading}
              view={clueView}
              allFound={isAllLampFound}
            />
          )}
        </TabPane>
        <TabPane tabId="not-completed-clues">
          {activeTab === "not-completed-clues" && (
            <Clues
              tab={activeTab}
              loading={loading}
              view={clueView}
              allFound={isAllLampFound}
            />
          )}
        </TabPane>
        <TabPane tabId="completed-clues">
          {activeTab === "completed-clues" && (
            <Clues
              tab={activeTab}
              loading={loading}
              view={clueView}
              allFound={isAllLampFound}
            />
          )}
        </TabPane>
        <TabPane tabId="clue-purchase">
          {advCluesCount === null ? (
            <div className={classes.spinnerWrapper}>
              <Spinner />
            </div>
          ) : (
            <CluesPurchase />
          )}
        </TabPane>
        {!isFreeTrial && unlockedCluesCount > 0 && (
          <TabPane tabId="unlocked-clues">
            {qrSelectedTab === "unlocked-clues" &&
              activeTab === "unlocked-clues" && <UnlockedClueModal />}
            {activeTab === "unlocked-clues" && (
              <Clues
                tab={activeTab}
                loading={loading}
                view={clueView}
                allFound={isAllLampFound}
              />
            )}
          </TabPane>
        )}
      </TabContent>
    </div>
  );
};

export default ClueDashboard;
