import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router";
import { Tooltip } from "reactstrap";
import { getRegion } from "src/api/regions";
import WishLamp from "src/assets/images/lamp.png";
import { RegionListProps } from "src/pages/Play/SelectRegions/Components/RegionList/types";
import TooltipContent from "src/pages/Play/SelectRegions/Components/TooltipContent";
import { setLampId } from "src/store/actions/clue";
import {
  getUsersSavedRegionList,
  saveRegion,
  updateSavedRegion,
} from "src/store/actions/regions";
import { useStoreActions } from "src/store/hooks";
import { Region } from "src/types/region";
import { useSeason } from "src/utils/helper";
import { getRegionInfo } from "src/utils/region";
import classes from "./styles.module.scss";

export type MarkerProps = {
  handleRegionSelect: (region: string) => void;
  handleHoveredRegion: (region?: string) => void;
  addedRegions: string[];
  handleRemoveRegion: (region: string) => void;
  regionSelectionFlow: boolean;
  hoveredCard?: string;
  hideTooltip?: boolean;
  currentHoveredRegion?: string;
  handleAddRegion: (region: string) => void;
  regionData: RegionListProps;
  disabledHover?: boolean;
};

export type TooltipState = {
  tooltipOpen: boolean;
};

export type TooltipStateProps = {
  [key: string]: TooltipState;
};

const positions: { [key: string]: { x: number; y: number } } = {
  "region-1": { x: 50, y: 150 },
  "region-2": { x: 200, y: 200 },
  "region-3": { x: 400, y: 200 },
  "region-4": { x: 600, y: 300 },
  "region-5": { x: 750, y: 250 },
  "region-6": { x: 100, y: 350 },
  "region-7": { x: 350, y: 350 },
  "region-8": { x: 350, y: 500 },
  "region-9": { x: 550, y: 450 },
  "region-10": { x: 700, y: 450 },
};

const Marker: React.FC<MarkerProps> = ({
  handleHoveredRegion,
  addedRegions,
  regionSelectionFlow,
  handleAddRegion,
  regionData,
  handleRemoveRegion,
  currentHoveredRegion,
  hideTooltip = false,
  disabledHover,
}) => {
  const { isAllLampFound } = getRegionInfo(regionData);
  const isGrayBackground = isAllLampFound;
  const [isOpen, setIsOpen] = useState(false);
  const isJoined = addedRegions.includes(regionData.slug);
  const markerPosition = positions[regionData.slug] ?? {};
  const regionId = regionData.slug.split("-")[1];
  const { currentSeason } = useSeason();
  const navigate = useNavigate();
  const actions = useStoreActions({
    getUsersSavedRegionList,
    saveRegion,
    updateSavedRegion,
  });

  const getCircleStyles = (region: string) => {
    const addedRegion = addedRegions.includes(region);

    if (isGrayBackground) {
      if (currentHoveredRegion === region) {
        return {
          stroke: "#FFFFFF",
          strokeWidth: "1px",
          cy: "-5px",
          r: "15",
          fill: "#B5B6B5",
        };
      }
      return {
        stroke: "#FFFFFF",
        strokeWidth: "1px",
        cy: "-5px",
        r: "15",
        fill: "#818381",
      };
    }
    if (addedRegion && !regionSelectionFlow) {
      return {
        stroke: "#FFFFFF",
        strokeWidth: "1px",
        cy: "-5px",
        r: "15",
        fill: "#6CBD3F",
      };
    } else {
      return {
        stroke: "#444444",
        strokeWidth: "1px",
        cy: "-5px",
        r: "15",
        fill: "white",
      };
    }
  };

  const getTextStyles = (region: string) => {
    if (isGrayBackground) {
      return {
        fill: "white",
      };
    }
    const addedRegion = addedRegions.includes(region);
    if (addedRegion && !regionSelectionFlow) {
      return {
        fill: "white",
      };
    } else {
      return {
        fill: "black",
      };
    }
  };

  const handleMarkerHover = (region: string) => {
    // if (regionData.lamp_count === 0) {
    //   return;
    // }
    if (hideTooltip) return;
    if (regionData.slug === region) {
      setIsOpen(true);
    } else {
      setIsOpen(false);
    }
    handleHoveredRegion(region);
  };

  const handleAddRegionWithTooltip = (region: string | undefined) => {
    if (isGrayBackground) {
      return;
    }
    if (!region) return;
    if (addedRegions.includes(region)) return;
    handleAddRegion(region);
  };

  const dispatch = useDispatch();
  const handleViewClues = async () => {
    const response = await getRegion(regionId.toString());
    const regionData: Region = response.data;
    const activeSeasons = regionData.seasons.filter((s) => !!s.lamp);

    const selectedSeason = regionData.seasons.find(
      (season) => season?.id?.toString() === currentSeason?.id.toString()
    );
    const lampId = selectedSeason?.lamp?.id || 0;
    dispatch(setLampId(lampId));

    if (activeSeasons.some((season) => season.id === currentSeason?.id)) {
      navigate(`/play/clues?region=${regionId}&season=${currentSeason?.id}`);
      return;
    }
    return navigate(
      `/play/clues?region=${regionId}&season=${activeSeasons[0]?.id}`
    );
  };

  const handleAddIndividualRegion = async (region: string) => {
    const regionId = region.split("-")[1];
    const formData = new FormData();
    formData.append("region_ids[]", `${regionId}`);
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const { status } = await actions.saveRegion(formData);
    if (status) {
      actions.getUsersSavedRegionList();
    }
  };

  const handleSaveRegion = async () => {
    await handleAddIndividualRegion(regionData.slug);
    // setIsOpen(false);
  };

  const handleRemoveRegions = async () => {
    const formData = new FormData();
    formData.append("removed_regions[]", `${regionId}`);
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const { status } = await actions.updateSavedRegion(formData);
    if (status) {
      handleRemoveRegion(regionData.slug);

      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      await actions.getUsersSavedRegionList();
    }
  };

  return (
    <>
      <g
        transform={`translate(${markerPosition.x}, ${markerPosition.y})`}
        id={regionData.slug}
        onMouseEnter={() => handleMarkerHover(regionData.slug)}
        onMouseLeave={() => handleMarkerHover("")}
      >
        <g transform="translate(30, -10)">
          <circle {...getCircleStyles(regionData.slug)}></circle>
          <text
            textAnchor="middle"
            fontFamily="Arial"
            {...getTextStyles(regionData.slug)}
          >
            {regionData.slug.split("-")[1]}
          </text>
        </g>
        <g>
          <image
            opacity={
              isGrayBackground && currentHoveredRegion !== regionData.slug
                ? 0.6
                : 1
            }
            className={classes.lampImage}
            id={"Tooltip-" + regionData.slug}
            href={WishLamp}
          />
        </g>
        {!disabledHover && (
          <Tooltip
            className="customTooltip"
            style={{
              cursor: "pointer",
              maxWidth: "100%",
              borderRadius: "16px",
              padding: "8px",
              background:
                "linear-gradient(122.25deg, rgba(18, 20, 41, 0.9) -9.74%, rgba(18, 20, 41, 0.3) 167.89%)",
              border: "0.732759px solid #FFFFFF",
              opacity: 1,
            }}
            delay={{ show: 1000, hide: 100 }}
            autohide={false}
            hideArrow
            isOpen={isOpen}
            target={"Tooltip-" + regionData.slug}
            toggle={() => setIsOpen(false)}
            onClick={() => handleAddRegionWithTooltip(regionData.slug)}
          >
            <TooltipContent
              content={regionData}
              isJoined={isJoined}
              handleViewClues={handleViewClues}
              handleRemoveRegions={handleRemoveRegions}
              handleSaveRegion={handleSaveRegion}
            />
          </Tooltip>
        )}
      </g>
    </>
  );
};

export default Marker;
